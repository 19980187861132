



























































































































import Vue from 'vue';
import { getUser } from '@/helper/handle-auth';
import { AuthResponse } from '@/model/auth';
import priceCalculator from '../service/price-calculator';


export default Vue.extend({
  name: 'offers',
  data: () => ({
    insurances: [],
    letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Alle'],
    draw: 0,
    search: '',
    start: 0,
    length: 5,
    recordsTotal: 0,
    recordsFiltered: 0,
    letter: '',
    sortBy: '',
    sortType: 'DESC',
    searchByField: '',
    searchByFieldValue: '',
    idSearchValue: '',
    processNameSearchValue: '',
    userSearchValue: '',
  }),
  mounted() {
    priceCalculator.fetch(this.draw, this.search, this.length, this.start, this.letter, this.sortBy, this.sortType, this.idSearchValue, this.processNameSearchValue, this.userSearchValue).then((response) => {
      this.insurances = response.data.data;
      this.recordsTotal = response.data.recordsTotal;
      this.recordsFiltered = response.data.recordsFiltered;
    });
    this.$emit('onId', '');
    this.$emit('getCustomerData', '');
  },
  watch: {
    length() {
      this.draw = 0;
      this.start = 0;
      this.sendRequest();
    },
    search() {
      this.draw = 0;
      this.start = 0;
      this.sendRequest();
    },
  },
  computed: {
    user(): AuthResponse | void {
      return getUser();
    },
    pages() {
      return this.recordsFiltered / this.length - 1;
    },
    roles() {
      const user: AuthResponse | void = getUser();
      if (user) return user.roles.split(',');
      return [];
    },
    isSuperAdmin(): boolean {
      return this.roles.indexOf('super-admin') > -1;
    },
  },
  methods: {
    redirectToRoute(id: number) {
      const path = `transport-insurance/${id}`;
      this.$router.push({
        path,
      }).catch((err) => {
        throw new Error(`Problem handling something: ${err}.`);
      });
    },
    sendRequest() {
      priceCalculator.fetch(this.draw, this.search, this.length, this.start, this.letter, this.sortBy, this.sortType, this.idSearchValue, this.processNameSearchValue, this.userSearchValue).then((response) => {
        this.insurances = response.data.data;
        this.recordsTotal = response.data.recordsTotal;
        this.recordsFiltered = response.data.recordsFiltered;
      });
    },
    nextPage() {
      this.draw += 1;
      this.start = this.draw * this.length;
      this.sendRequest();
    },
    prevPage() {
      this.draw -= 1;
      this.start = this.draw * this.length;
      this.sendRequest();
    },
    letterClick(currentLetter: string) {
      if (currentLetter !== 'All') {
        this.letter = currentLetter;
      } else {
        this.letter = '';
      }
      this.draw = 0;
      this.start = 0;
      this.sendRequest();
    },
    exportClick() {
      priceCalculator.exportToExcel().then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'all_Insurances.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        // const { message } = response.data;
        // Vue.toasted.show(message, {
        //   type: 'success',
        // });
      });
    },
    orderBy(column: string) {
      if (this.sortBy === column) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.sortType = 'ASC';
      }
      this.sortBy = column;
      this.sendRequest();
    },
  },
});
